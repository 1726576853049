<template>
    <div>
        <v-card min-width="500px" title="MODIFICA COLLO" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: fit-content; row-gap: 15px; padding-top: 30px;">
            <div class="warning-text-container">
                <p class="warning-text">SELEZIONA LO STATO DEL COLLO:<br><span class="cod-collo">{{ this.collo.ldv }}</span></p>
            </div>
            <div>
                <v-select
                    :items="['DA_RICEVERE', 'LAVORATO_RETURNS', 'LAVORATO_ORDERS', 'RICEVUTO_MAGAZZINO', 'IN_USCITA', 'SPEDITO']"
                    label="STATO"
                    variant="outlined"
                    v-model="this.stato"
                    required
                    width="300px"
                    style="width: 300px"
                ></v-select>
                <v-textarea
                    v-model="this.noteCollo.text"
                    label="NOTE"
                    rows="7"
                    no-resize
                    style="width:300px"
                    hide-details
                    variant="outlined"
                ></v-textarea>
                <small style="color: red">{{ this.errorMessage }}</small>
            </div>
            <v-card-actions style="column-gap: 50px;">
                <v-btn 
                    text="INVIA" 
                    variant="outlined" 
                    class="bg-green" 
                    width="100" 
                    height="50"
                    @click="modificaCollo">
                </v-btn>
                <v-btn 
                    variant="outlined" 
                    class="bg-red" 
                    width="100" 
                    height="50"
                    text="CHIUDI"
                    @click="closeModificaCollo"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    import Swal from 'sweetalert2';
    const axios = require('axios');
    export default {
        name: "ModificaCollo",

        data() {
            return {
                stato: null,
                errorMessage: "",
                noteCollo: {
                    text: ""
                },
            }
        },

        props: {
            collo: null,
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        mounted() {
            this.stato = this.collo.stato
            this.noteCollo.text = this.collo.note
        },

        methods: {
            async modificaCollo() {
                this.errorMessage = "";
                let date = new Date()
                let colloModifica = {
                    ldv: this.collo.ldv,
                    stato: this.stato,
                    createDate: date,
                    bloccato: this.collo.bloccato,
                    note: this.noteCollo.text
                }
                const apiUrl = `${this.$apiBaseUrl}/colli`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.post(apiUrl, colloModifica, config);
                    console.log('Response:', response.data);
                    this.store.dispatch("changeBodyClass", "")
                    this.store.dispatch('closeModificaCollo')
                    Swal.fire({
                        title: "STATO COLLO MODIFICATO",
                        text: `Lo stato del collo ${this.collo.ldv} è stato modificato.`,
                        icon: "success",
                    });
                } catch (error) {
                    console.log(error);
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nella modifica del collo`,
                        icon: "error",
                    });
                }
            },
            closeModificaCollo() {
                this.store.dispatch("changeBodyClass", "")
                this.store.dispatch('closeModificaCollo')
            }
        },
    }
</script>

<style scoped>
        .warning-text {
        text-align: center;
        font-size: 1.2rem;
        width: 400px;
    }

    .nome-utente {
        font-weight: 600;
        font-size: 1.5rem;
    }

    .v-list-item--active {
        background-color: #2196f3;
        color: white;
    }
</style>