<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <div class="filters-actions-container">
        <form @submit.prevent="searchOrdine" class="filters">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: end;
              row-gap: 5px;
            "
          >
            <v-dialog width="375px">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  width="200"
                  variant="outlined"
                  text="DATA DA"
                  height="48px"
                ></v-btn>
              </template>
              <template v-slot:default="{ isActive }">
                <v-card title="SELEZIONA DATA DA">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <v-date-picker
                      style="border: 1px solid #d0d0d0 !important"
                      v-model="this.dataDa"
                    ></v-date-picker>
                  </div>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text="CANCELLA"
                      @click="this.dataDa = null"
                      class="bg-red"
                      variant="outlined"
                    ></v-btn>
                    <v-btn
                      text="INVIA"
                      @click="
                        () => {
                          isActive.value = false;
                        }
                      "
                      class="bg-green"
                      variant="outlined"
                    ></v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <small>{{ this.formatDate(dataDa) }}</small>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: end;
              row-gap: 5px;
            "
          >
            <v-dialog width="375px">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  width="200"
                  variant="outlined"
                  text="DATA A"
                  height="48px"
                ></v-btn>
              </template>
              <template v-slot:default="{ isActive }">
                <v-card title="SELEZIONA DATA A">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <v-date-picker
                      style="border: 1px solid #d0d0d0 !important"
                      v-model="this.dataA"
                    ></v-date-picker>
                  </div>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text="CANCELLA"
                      @click="
                        () => {
                          dataA = null;
                        }
                      "
                      class="bg-red"
                      variant="outlined"
                    ></v-btn>
                    <v-btn
                      text="INVIA"
                      @click="
                        () => {
                          isActive.value = false;
                        }
                      "
                      class="bg-green"
                      variant="outlined"
                    ></v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <small>{{ this.formatDate(dataA) }}</small>
          </div>
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="CERCA ORDINE"
            append-inner-icon="mdi-magnify"
            hide-details
            style="width: 200px !important; flex: none !important"
            v-model="searchingOrdine.text"
            :clearable="true"
          ></v-text-field>
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="CERCA LDV"
            append-inner-icon="mdi-magnify"
            hide-details
            style="width: 200px !important; flex: none !important"
            v-model="searchingLdV.text"
            :clearable="true"
          ></v-text-field>
          <v-btn
            type="submit"
            icon="mdi-magnify"
            class="bg-blue"
            variant="outlined"
          />
          <v-btn
            variant="outlined"
            height="48"
            width="120"
            class="bg-red"
            @click="resetFiltri"
            >RESET FILTRI</v-btn
          >
        </form>
      </div>
    </div>
    <div style="width: 95%">
      <v-data-table-server
        :fixed-header="true"
        v-model:items-per-page-options="options"
        :loading="loading"
        :headers="headers"
        :items="this.ordiniServer"
        v-model:items-per-page="itemsPerPage"
        :items-length="totalItems"
        @update:options="loadItems"
        v-model:page="currentPage"
        style="
          width: 100%;
          border: 1px solid #d0d0d0;
          border-radius: 5px;
          max-height: 475px;
        "
      >
        <template v-slot:header.qcKoCount>
          <v-chip color="red" variant="flat"> QC KO </v-chip>
        </template>
        <template v-slot:header.qcOkCount>
          <v-chip color="green" variant="flat"> QC OK </v-chip>
        </template>
        <template v-slot:item.dataAggiornamento="{ item }">
          <p
            v-if="
              item.dataAggiornamento === null || item.dataAggiornamento === ''
            "
          >
            NESSUNO
          </p>
          <p v-else>{{ this.formattaDataOra(item.dataAggiornamento) }}</p>
        </template>
        <template v-slot:item.emailAggiornatore="{ item }">
          <p
            v-if="
              item.emailAggiornatore === null || item.emailAggiornatore === ''
            "
          >
            NESSUNO
          </p>
          <p v-else>{{ item.emailAggiornatore }}</p>
        </template>
        <template v-slot:item.id="{ item }">
          <v-icon
            icon="mdi-information-outline"
            @click="openDetails(item.orderItems, item.orderNumber)"
            color="blue"
            size="x-large"
          ></v-icon>
        </template>
      </v-data-table-server>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
import Swal from "sweetalert2";
const axios = require("axios");
const { DateTime } = require("luxon");
export default {
  name: "OrdiniTable",

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
  },

  data() {
    return {
      headers: [
        { title: "CODICE ORDINE", value: "orderNumber", sortable: false },
        {
          title: "STATO",
          value: "orderStatusId",
          sortable: false,
          align: "center",
        },
        {
          title: "QC OK",
          value: "qcOkCount",
          sortable: false,
          align: "center",
        },
        {
          title: "QC KO",
          value: "qcKoCount",
          sortable: false,
          align: "center",
        },
        {
          title: "MANCANTI",
          value: "missingCount",
          sortable: false,
          align: "center",
        },
        {
          title: "ULTIMO AGGIORNAMENTO",
          value: "dataAggiornamento",
          sortable: false,
        },
        {
          title: "AUTORE AGGIORNAMENTO",
          value: "emailAggiornatore",
          sortable: false,
        },
        { title: "", value: "id", sortable: false },
      ],
      searchingOrdine: {
        text: "",
      },
      searchingLdV: {
        text: "",
      },
      loading: true,
      ordiniServer: [],
      itemsPerPage: 10,
      totalItems: 0,
      currentPage: 1,
      dataA: null,
      dataDa: null,
      options: [10, 20, 30, 40, 50],
    };
  },

  mounted() {
    this.dataDa = new Date();
    let date = new Date();
    date.setDate(date.getDate() + 7);
    this.dataA = date;

    this.searchOrdine();
  },

  methods: {
    searchOrdine() {
      this.itemsPerPage = 10;
      this.currentPage = 1;
      this.loadItems({ page: 1, itemsPerPage: 10, sortBy: [{}] });
    },
    resetFiltri() {
      this.searchingOrdine.text = "";
      this.searchingLdV.text = "";
      this.dataDa = new Date();
      let date = new Date();
      date.setDate(date.getDate() + 7);
      this.dataA = date;

      this.searchOrdine();
    },
    openDetails(contenutoOrdine, numOrdine) {
      console.log(contenutoOrdine, numOrdine);
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("showContenutoOrdine", [contenutoOrdine, numOrdine]);
      console.log(
        this.store.state.ordini.numOrdineSelezionato,
        this.store.state.ordini.contenutoOrdineSelezionato
      );
    },
    formatDate(inputDate) {
      if (inputDate === null) {
        return "nessuna data selezionata";
      } else {
        // Parse the input date string
        const date = new Date(inputDate);

        // Get day, month, and year components
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Construct the formatted date string
        const formattedDate = `${day} / ${month} / ${year}`;

        return formattedDate;
      }
    },
    formattaDataOra(date) {
      return this.dateTime
        .fromISO(date + "Z")
        .setZone("Europe/Rome")
        .setLocale("it")
        .toFormat("dd/MM/yyyy | HH:mm:ss");
    },
    async loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true;

      this.currentPage = page;
      this.itemsPerPage = itemsPerPage;

      let filters = {
        orderNumber:
          this.searchingOrdine.text == "" ? null : this.searchingOrdine.text,
        trackingNumber:
          this.searchingLdV.text == "" ? null : this.searchingLdV.text,
        dataRicezioneDa:
          this.dataDa == null
            ? null
            : DateTime.fromJSDate(this.dataDa)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        dataRicezioneA:
          this.dataA == null
            ? null
            : DateTime.fromJSDate(this.dataA)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
      };
      console.log(sortBy, page, itemsPerPage, filters);
      const apiUrl = `${this.$apiBaseUrl}/orders`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      console.log(sortBy);

      let urlParams = {
        filtro: filters,
        sortBy: sortBy[0],
        page: page - 1,
        itemsPage: itemsPerPage,
      };

      console.log(urlParams);

      try {
        const response = await axios.post(apiUrl, urlParams, config);
        console.log("Response:", response.data);
        this.ordiniServer = response.data.orders;
        this.totalItems = response.data.length;
        this.loading = false;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento degli ordini`,
          icon: "error",
        });
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
.filters-actions-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10px;
  width: fit-content;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-text-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-input {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}

.user-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-card-title {
  font-size: 2rem;
}

.warning-text {
  font-size: 1.2rem;
  text-align: center;
  width: 400px;
}

.v-list-item--active {
  background-color: #2196f3;
  color: white;
}
</style>
