<template>
    <div>
        <v-card min-width="500px" title="ELIMINA COLLO" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: fit-content; row-gap: 50px; padding-top: 30px;">
            <div class="warning-text-container">
                <p class="warning-text">VUOI ELIMINARE IL COLLO:<br><span class="cod-collo">{{ this.collo.ldv }} ?</span></p>
            </div>
            <v-card-actions style="column-gap: 50px;">
                <v-btn 
                    text="ELIMINA" 
                    variant="outlined" 
                    class="bg-green" 
                    width="100" 
                    height="50"
                    @click="eliminaCollo">
                </v-btn>
                <v-btn 
                    variant="outlined" 
                    class="bg-red" 
                    width="100" 
                    height="50"
                    text="CHIUDI"
                    @click="closeEliminaCollo"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    import Swal from 'sweetalert2';
    const axios = require('axios');
    export default {
        name: "EliminaCollo",

        data() {
            return {
            }
        },

        props: {
            collo:  {
                type: Object,
            },
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        methods: {
            async eliminaCollo() {
                const apiUrl = `${this.$apiBaseUrl}/colli/${this.collo.ldv}`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.delete(apiUrl, config);
                    console.log('Response:', response.data);
                    this.store.dispatch("changeBodyClass", "")
                    this.store.dispatch('closeEliminaCollo')
                    Swal.fire({
                        title: "COLLO ELIMINATO",
                        text: `Il collo ${this.collo.ldv} è stato eliminato.`,
                        icon: "success",
                    });
                } catch (error) {
                    console.log(error);
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nell'eliminazione del collo`,
                        icon: "error",
                    });
                }
                
            },
            closeEliminaCollo() {
                this.store.dispatch("changeBodyClass", "")
                this.store.dispatch('closeEliminaCollo')
            }
        },
    }
</script>

<style scoped>
        .warning-text {
        text-align: center;
        font-size: 1.2rem;
        width: 400px;
    }

    .nome-utente {
        font-weight: 600;
        font-size: 1.5rem;
    }

    .v-list-item--active {
        background-color: #2196f3;
        color: white;
    }
</style>