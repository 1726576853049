<template>
  <div>
    <v-card width="1050">
      <v-card-title
        >DETTAGLI BANCALE N° {{ this.idBancaleSelected }}</v-card-title
      >
      <v-card-text>
        <v-container
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: left;
          "
        >
          <v-text-field
            v-model="this.searchingCollo.text"
            label="CERCA LDV"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            style="width: 100%; align-self: flex-end"
            single-line
          ></v-text-field>
          <v-data-table
            v-model:items-per-page-options="options"
            :fixed-header="true"
            :search="this.searchingCollo.text"
            :items="this.contenutoBancale"
            :headers="this.headers"
            :loading="this.loading"
            style="
              border: 1px solid #d0d0d0;
              border-radius: 5px;
              max-height: 372px;
              margin-top: 10px;
            "
          >
            <template v-slot:item.emailAggiornatore="{ item }">
              <p
                v-if="
                  item.emailAggiornatore === null ||
                  item.emailAggiornatore === 'null'
                "
              >
                NESSUNO
              </p>
              <p v-else>{{ item.emailAggiornatore }}</p>
            </template>
            <template v-slot:item.data_aggiornamento="{ item }">
              <p
                v-if="
                  item.data_aggiornamento === null ||
                  item.data_aggiornamento === 'null'
                "
              >
                NESSUNO
              </p>
              <p v-else>{{ this.formattaDataOra(item.data_aggiornamento) }}</p>
            </template>
            <template v-slot:item.id="{ item }">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  column-gap: 10px;
                "
              >
                <v-icon
                  icon="mdi-transfer-right"
                  @click="showSpostaCollo(item.ldv)"
                  color="blue"
                  size="x-large"
                ></v-icon>
                <v-icon
                  icon="mdi-delete"
                  @click="rimuoviCollo(item.ldv)"
                  color="red"
                  size="x-large"
                ></v-icon>
              </div>
            </template>
          </v-data-table>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: start;
              column-gap: 10px;
            "
          >
            <v-btn
              variant="outlined"
              height="48px"
              style="margin-top: 20px; background-color: #1d6f42; color: white"
              @click="exportExcel"
              >ESPORTA EXCEL</v-btn
            >
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" variant="outlined" @click="closeDetails">
          CHIUDI
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog width="fit-content" v-model="this.dialogSpostaCollo">
      <v-card>
        <v-card-title>SPOSTA COLLO {{ this.editingLdv }}</v-card-title>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 20px;
            margin: 20px;
          "
        >
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="INSERISCI CODICE BANCALE"
            hide-details
            style="width: 300px !important; flex: none !important"
            v-model="idBancale.text"
            :clearable="true"
          ></v-text-field>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text="ANNULLA"
            @click="this.dialogSpostaCollo = false"
            class="bg-red"
            variant="outlined"
          ></v-btn>
          <v-btn
            text="INVIA"
            @click="spostaCollo()"
            class="bg-green"
            variant="outlined"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useStore } from "vuex";
const { DateTime } = require("luxon");
import Swal from "sweetalert2";
const axios = require("axios");
export default {
  name: "ContenutoBancale",

  props: {
    idBancaleSelected: {
      type: Number,
    },
    ldv: {
      type: String,
    },
    open: {
      type: Boolean,
    },
  },

  mounted() {
    this.loadDati();
    this.searchingCollo.text = this.ldv;
    console.log(this.searchingCollo.text);
    console.log(this.ldv);
  },

  data() {
    return {
      headers: [
        { title: "LDV", value: "ldv", sortable: true },
        {
          title: "N° PEZZI",
          value: "numeroPezzi",
          sortable: true,
          align: "center",
        },
        { title: "STATO", value: "stato", sortable: true },
        {
          title: "ULTIMO AGGIORNAMENTO",
          value: "data_aggiornamento",
          sortable: true,
        },
        {
          title: "ULTIMO AGGIORNATORE",
          value: "emailAggiornatore",
          sortable: true,
        },
        { title: "", value: "id", sortable: false },
      ],
      searchingCollo: {
        text: "",
      },
      options: [10, 20, 30, 40, 50],
      contenutoBancale: [],
      loading: true,
      dialogSpostaCollo: false,
      idBancale: {
        text: "",
      },
      editingLdv: null,
    };
  },

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  methods: {
    async exportExcel() {
      const apiUrl = `${this.$apiBaseUrl}/export/colliByPallet/${this.idBancaleSelected}`;
      const bearerToken = this.$keycloak.token;

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: "blob",
      };
      try {
        const response = await axios.get(apiUrl, config);
        console.log(response);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response.data);
        link.download = "contenuto-bancale.csv";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento del file excel`,
          icon: "error",
        });
      }
    },
    async loadDati() {
      this.loading = true;
      const apiUrl = `${this.$apiBaseUrl}/pallet/findColliById/${this.idBancaleSelected}`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.get(apiUrl, config);
        console.log("Response:", response.data);
        this.contenutoBancale = response.data.colloViewList;
        this.loading = false;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento dei dettagli del bancale`,
          icon: "error",
        });
      }
    },
    closeDetails() {
      this.store.dispatch("changeBodyClass", "");
      this.store.dispatch("closeContenutoBancale");
    },
    formattaDataOra(date) {
      return this.dateTime
        .fromISO(date + "Z")
        .setZone("Europe/Rome")
        .setLocale("it")
        .toFormat("dd/MM/yyyy | HH:mm:ss");
    },
    async rimuoviCollo(ldv) {
      Swal.fire({
        title: "ATTENZIONE",
        text: `Sei sicuro di voler rimuovere il collo ${ldv} dal bancale ${this.idBancaleSelected}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        confirmButtonText: "OK",
        cancelButtonText: "ANNULLA",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const apiUrl = `${this.$apiBaseUrl}/contetPallet/`;
          const bearerToken = this.$keycloak.token;
          console.log(bearerToken);

          let contentPallet = {
            ldv: ldv,
            vecchioPallet: this.idBancaleSelected,
          };

          const config = {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          };

          try {
            const response = await axios.post(apiUrl, contentPallet, config);
            console.log("Response:", response.data);
            console.log("confirmed");
            Swal.fire({
              title: "COLLO RIMOSSO",
              text: `Il collo ${ldv} è stato rimosso dal bancale ${this.idBancaleSelected}`,
              icon: "success",
            });
            this.loadDati();
          } catch (error) {
            console.error("Error:", error.message);
            Swal.fire({
              title: "ERRORE",
              text: `C'è stato un errore nella rimozione del collo`,
              icon: "error",
            });
          }
        }
      });
    },
    showSpostaCollo(ldv) {
      this.dialogSpostaCollo = true;
      this.editingLdv = ldv;
    },
    async spostaCollo() {
      const apiUrl = `${this.$apiBaseUrl}/contetPallet/`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      let contentPallet = {
        ldv: this.editingLdv,
        vecchioPallet: this.idBancaleSelected,
        nuovoPallet: this.idBancale.text,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.put(apiUrl, contentPallet, config);
        console.log("Response:", response.data);
        console.log("confirmed");
        this.dialogSpostaCollo = false;
        Swal.fire({
          title: "COLLO SPOSTATO",
          text: `Il collo ${this.editingLdv} è stato spostato nel bancale ${this.idBancale.text}`,
          icon: "success",
        });
        this.loadDati();
      } catch (error) {
        console.error("Error:", error.message);
        this.dialogSpostaCollo = false;
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nello spostamento del collo`,
          icon: "error",
        });
      }
    },
  },

  watch: {
    open: function (newVal) {
      if (newVal) {
        this.loadDati();
      }
    },
  },
};
</script>

<style scoped></style>
